body {
  margin: 0;
  padding: 0;
  font-family: 'FK', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,0.9);
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-Regular.woff2') format('woff2'),
        url('./type/FKGrotesk-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-Italic.woff2') format('woff2'),
        url('./type/FKGrotesk-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-Medium.woff2') format('woff2'),
        url('./type/FKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-MediumItalic.woff2') format('woff2'),
        url('./type/FKGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-Bold.woff2') format('woff2'),
        url('./type/FKGrotesk-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url('./type/FKGrotesk-BoldItalic.woff2') format('woff2'),
        url('./type/FKGrotesk-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
