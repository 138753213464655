body {
  margin: 0;
  padding: 0;
  font-family: 'FK', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,0.9);
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-Regular.5371fbbf.woff2) format('woff2'),
        url(/static/media/FKGrotesk-Regular.725c6de3.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-Italic.c6d54263.woff2) format('woff2'),
        url(/static/media/FKGrotesk-Italic.a3289a54.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-Medium.5e54a20d.woff2) format('woff2'),
        url(/static/media/FKGrotesk-Medium.4c8e9499.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-MediumItalic.4cb84713.woff2) format('woff2'),
        url(/static/media/FKGrotesk-MediumItalic.139f8eae.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-Bold.f7610340.woff2) format('woff2'),
        url(/static/media/FKGrotesk-Bold.9f888286.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FK';
    src: url(/static/media/FKGrotesk-BoldItalic.8baa7f47.woff2) format('woff2'),
        url(/static/media/FKGrotesk-BoldItalic.bde213aa.woff) format('woff');
    font-weight: 600;
    font-style: italic;
}

